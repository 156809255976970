/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-threeimages__imagescontainer {
  display: grid;
  grid-template-columns: 1fr;
}

.w-threeimages__imagecontainer {
  position: relative;
  aspect-ratio: 1/1;
}
@media (hover: hover) {
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontwhite,
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontwhitegoldbg {
    background-color: #fff;
  }
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontwhite {
    color: #000;
  }
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontblack,
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontblackgoldbg {
    background-color: rgba(0, 0, 0, 0.85);
  }
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontblack {
    color: #fff;
  }
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontblackgoldbg,
  .w-threeimages__imagecontainer[href]:hover .w-threeimages__title--fontwhitegoldbg {
    color: rgba(188, 157, 86, 0.83);
  }
}

.w-threeimages__title {
  position: absolute;
  bottom: 24px;
  left: 24px;
  margin: 0;
  padding: 7px 5px 0px 5px;
  transition: background 0.3s, color 0.3s;
}
.w-threeimages__title--fontwhite, .w-threeimages__title--fontwhitegoldbg {
  color: #fff;
}
.w-threeimages__title--fontblack, .w-threeimages__title--fontblackgoldbg {
  color: rgba(0, 0, 0, 0.85);
}
.w-threeimages__title--fontblackgoldbg, .w-threeimages__title--fontwhitegoldbg {
  background-color: rgba(188, 157, 86, 0.83);
}

@media (max-width: 575.98px) {
  .w-threeimages__title {
    font-size: 40px;
  }
}
@media (max-width: 991.98px) {
  .w-threeimages__imagecontainer--0,
  .w-threeimages__imagecontainer--2 {
    order: 2;
  }
  .w-threeimages__imagecontainer--1 {
    order: 1;
  }
}
@media (min-width: 576px) {
  .w-threeimages__imagecontainer {
    aspect-ratio: 4/3;
  }
}
@media (min-width: 768px) {
  .w-threeimages__imagecontainer {
    aspect-ratio: 16/9;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .w-threeimages__imagescontainer {
    grid-template: 1fr 1fr/1fr 1fr;
    gap: 2rem;
  }
  .w-threeimages__imagecontainer {
    height: 500px;
    aspect-ratio: initial;
  }
  /*
     Grid:
      +---+---+
      | 1 | 1 |
      +---+---+
      | 0 | 2 |
      +---+---+
  */
  .w-threeimages__imagecontainer--1 {
    grid-row: 1/2;
    grid-column: 1/3;
  }
  .w-threeimages__imagecontainer--0 {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .w-threeimages__imagecontainer--2 {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .w-threeimages__imagecontainer--1 .w-threeimages__title {
    font-size: 100px;
  }
}
@media (min-width: 1200px) {
  .w-threeimages__imagescontainer {
    grid-template: 1fr/400px 2fr 400px;
    gap: 1rem;
  }
  .w-threeimages__imagecontainer {
    height: 500px;
    aspect-ratio: initial;
  }
  .w-threeimages__imagecontainer--0 .w-threeimages__title {
    left: 0;
    right: auto;
  }
}
@media (min-width: 1400px) {
  .w-threeimages__imagescontainer {
    gap: 2rem;
  }
}