/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-photocollage {
  max-width: 100vw;
  overflow-x: hidden;
}
.w-photocollage .w-photocollage__imagecontainer--0 {
  grid-area: a0;
}
.w-photocollage .w-photocollage__imagecontainer--1 {
  grid-area: a1;
}
.w-photocollage .w-photocollage__imagecontainer--2 {
  grid-area: a2;
}
.w-photocollage .w-photocollage__imagecontainer--3 {
  grid-area: a3;
}
.w-photocollage .w-photocollage__imagecontainer--4 {
  grid-area: a4;
}
.w-photocollage .w-photocollage__imagecontainer--5 {
  grid-area: a5;
}

.w-photocollage__grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 200px);
  gap: 10px;
  grid-template-areas: "a0 a0 a0 a0 a0 a0" "a1 a1 a2 a2 a2 a2" "a3 a3 a3 a4 a4 a4";
}
@media (min-width: 768px) {
  .w-photocollage__grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 20vw);
    gap: 25px 30px;
    grid-template-areas: "a0 a0 a0 a1" "a2 a2 a3 a4";
  }
}

.w-photocollage__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}