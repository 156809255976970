/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-products_links {
  background-color: rgba(183, 183, 183, 0.22);
  padding: 48px var(--hk-widget-padding-x);
}
.w-products_links__columns {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.w-products_links__line {
  background-color: var(--color-hk-gold);
  margin: 0 60px;
  height: 1px;
}
.w-products_links__columntitle {
  margin-bottom: 20px;
  color: var(--color-hk-gold);
}
.w-products_links__linkblocks-left, .w-products_links__linkblocks-right {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px 32px;
}
.w-products_links__linkblock {
  padding: 16px 0;
}
.w-products_links__listtitle.btn {
  margin-bottom: 26px;
  min-width: unset;
}
.w-products_links .w-products_links__productlink {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  line-height: 28px;
  font-family: "Futura Std Condensed";
  font-weight: 300;
}