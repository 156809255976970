/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
section.widget--ambassadors {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 5rem;
}
section.widget--ambassadors .background-pattern {
  z-index: 1;
}
section.widget--ambassadors .background-pattern__pattern {
  opacity: 0.32;
}

.w-ambassadors {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  z-index: 10;
}
.w-ambassadors__quote {
  text-align: center;
  text-transform: uppercase;
}
.w-ambassadors__ambassador {
  background-color: #fff;
  border: 1px solid var(--color-hk-gold);
  padding: 16px;
  display: grid;
  gap: 2rem 1rem;
  margin: 0 auto;
}
.w-ambassadors__meet {
  font-family: "Futura Std Condensed";
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 3px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
}
.w-ambassadors__name, .w-ambassadors__organization {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
.w-ambassadors__organization {
  font-style: italic;
}
.w-ambassadors__description {
  color: rgba(0, 0, 0, 0.85);
}

.w-ambassadors__image {
  max-width: 100%;
}

@media (min-width: 576px) {
  .w-ambassadors__ambassador {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
  .w-ambassadors__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}