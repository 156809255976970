/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-slideshow {
  position: relative;
  max-height: 75vh;
  height: 626px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}
.w-slideshow__imagecontainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.w-slideshow__imagecontainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0));
}
@media (min-width: 768px) {
  .w-slideshow__imagecontainer:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0));
  }
}
.w-slideshow__image {
  width: 100%;
  height: 100%;
}
.w-slideshow__text {
  position: relative;
  color: #fff;
  z-index: 10;
  text-align: left;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .w-slideshow__text {
    text-align: center;
    align-items: center;
    justify-content: flex-end;
  }
}
.w-slideshow .c-slider {
  height: 100%;
}
.w-slideshow .c-slider .swiper-container,
.w-slideshow .c-slider .swiper-wrapper,
.w-slideshow .c-slider .swiper-slide {
  height: 100%;
}
.w-slideshow .c-slider .swiper {
  width: 100%;
  height: 100%;
}
.w-slideshow .c-slider .swiper-container {
  width: 100%;
}
.w-slideshow .c-slider .swiper-button-next, .w-slideshow .c-slider .swiper-button-prev {
  color: #000;
}
.w-slideshow .c-slider .swiper-slide img {
  width: 100%;
  height: 100%;
}
.w-slideshow .swiper-progress-bar-container {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  top: 32px;
  z-index: 100;
  pointer-events: none;
}
@media (min-width: 768px) {
  .w-slideshow .swiper-progress-bar-container {
    top: auto;
    bottom: 50px;
    left: 6px;
  }
}
.w-slideshow .swiper-progress-bar {
  position: absolute;
  width: calc(100% - var(--bs-gutter-x));
  max-width: 100%;
  display: block;
  height: 1px;
  bottom: 0;
}
@media (min-width: 768px) {
  .w-slideshow .swiper-progress-bar {
    width: 150px;
  }
}
.w-slideshow .swiper-progress-bar .slide_progress-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  clear: both;
  bottom: 0;
  left: 0;
  height: 1px;
}
.w-slideshow .swiper-progress-bar .slide_progress-bar .slide-progress-bar-bar {
  position: absolute;
  height: 1px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: left;
  width: 100%;
  transform: scaleX(0);
}
.w-slideshow .w-slideshow__imagecontainer.w-slideshow__imagecontainer--notext::after {
  background-image: none;
}

.w-slideshow__videoiframe {
  position: absolute;
  top: 50%;
  left: 50%;
  aspect-ratio: 16/9;
  min-height: 100%;
  min-width: 115%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
@media screen and (min-width: 960px) {
  .w-slideshow__videoiframe {
    width: 100%;
  }
}

.w-slideshow__videoiframe2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 20;
  pointer-events: none;
}

.w-slideshow--video {
  max-height: unset;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .w-slideshow--video {
    max-height: 100vh;
    height: auto;
    aspect-ratio: 16/9;
  }
}
.w-slideshow--video .w-slideshow__imagecontainer {
  overflow: hidden;
}

html.schatje .w-slideshow__imagecontainer:after {
  display: none;
}