/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  --header-logo-height-container: 45px;
  --header-logo-height: 55px;
}

html.schatje .header {
  position: static;
}
html.schatje .header__container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}
html.schatje .header__logo {
  display: inline-flex;
  flex-direction: column;
  gap: 2rem;
  height: auto;
}
html.schatje .header__logoimg {
  position: static;
  transform: none;
}
html.schatje .header__logosubtitle {
  font-size: 50px;
  line-height: 100%;
  font-weight: 300;
  color: var(--color-hk-gold) !important;
  letter-spacing: 16px;
  text-transform: uppercase;
}
html.schatje .header__menu,
html.schatje .header__side {
  display: none !important;
}

.offcanvas,
.dropdown-menu {
  --bs-dropdown-border-width: 0;
  --bs-dropdown-font-size: 28px;
  --bs-nav-link-font-size: var(--bs-dropdown-font-size);
  --bs-dropdown-link-active-bg: none;
  --bs-dropdown-link-active-color: inherit;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: relative;
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: #fff;
}
.header__side {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 768px) {
  .header {
    position: sticky;
    top: 0;
    background-color: #f7f7f7;
    z-index: 100;
  }
}
.header .c-languagemenu .c-languagemenu__dropdown:hover > .dropdown-menu {
  display: flex;
}
.header .c-languagemenu .dropdown-menu {
  min-width: 40px;
  --bs-dropdown-bg: var(--color-hk-gold);
  padding: 0;
}
.header .c-languagemenu .dropdown-menu > li {
  justify-content: center;
}
.header .c-languagemenu .dropdown-menu.show {
  flex-direction: column;
}
.header .c-languagemenu .dropdown-menu a img {
  transition: transform 0.3s;
}
@media (hover: hover) {
  .header .c-languagemenu .dropdown-menu a:hover img {
    transform: scale(1.3);
  }
}
.header .c-languagemenu .dropdown-toggle::after {
  display: none;
}
.header .c-languagemenu__items {
  flex-direction: column;
  line-height: 100%;
}
.header .c-languagemenu__items.shpw {
  display: flex;
}
.header .c-languagemenu__items li:last-child a {
  border: 0;
}
.header .c-languagemenu__items a {
  color: #fff;
  font-size: 20px;
  line-height: 100%;
  display: block;
  border-bottom: 1px solid #fff;
  padding: 12px 8px;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s, background 0.3s;
}
@media (hover: hover) {
  .header .c-languagemenu__items a:hover {
    border-radius: inherit;
    background: var(--color-hk-gold-hover-dark);
  }
}

.header .logocontainer {
  display: flex;
  justify-content: center;
}
.header .logocontainer img {
  height: var(--header-logo-height);
}
.header__logo {
  position: relative;
  height: var(--header-logo-height-container);
}

.header__logoimg {
  transform: translateY(-2px);
  position: absolute;
  top: 0;
  left: 0;
}

.header__container {
  height: 100%;
  display: grid;
  grid-template-areas: "logo menu side";
  grid-template-columns: auto 1fr;
  align-items: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
}
.header__container .header__menu {
  grid-area: menu;
}
.header__container .header__logo {
  grid-area: logo;
}
.header__container .header__side {
  grid-area: side;
}
.header__search {
  display: flex;
}

.header__hamburger {
  display: flex;
  position: relative;
}

.header__search,
.header__hamburger {
  cursor: pointer;
  font-size: 20px;
}
@media (max-width: 768px) {
  .header__search,
  .header__hamburger {
    font-size: 28px;
  }
}

.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ww-menuitems__menulink,
a.header__contactlink,
.mobilemenu a {
  text-decoration: none;
  color: #000 !important;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
}

.header .ww-dropdown-icon-container {
  display: inline-block;
  transform: rotate(90deg);
  margin-left: 7px;
}

.header__desktopmenu .ww-menuitems {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.header__desktopmenu > ul > li {
  line-height: 1;
}

.header__submenu.header__submenu--0 > .ww-menuitems {
  padding-left: 0;
  padding-bottom: 30px !important;
  max-width: initial !important;
}

.header__desktopmenu > ul > li > .header__submenu {
  display: none;
  animation: fade-in 300ms forwards;
  position: absolute;
  z-index: 100;
  z-index: 300;
  left: 0;
  top: calc(100% - 25px);
  padding-top: 25px;
  width: 100vw;
  line-height: 130%;
}
.header__desktopmenu > ul > li > .header__submenu > ul {
  background-color: #f0f0f0;
  width: 100%;
  padding: 30px 92px 0 92px;
  padding: 30px 0 0 0;
  padding-left: var(--hk-page-side-padding);
  align-items: flex-start;
  gap: 5px;
}
.header__desktopmenu > ul > li > .header__submenu > ul .ww-dropdown-icon-container {
  display: none;
}
@media (min-width: 1250px) {
  .header__desktopmenu > ul > li > .header__submenu > ul {
    padding-left: calc((100vw - 1200px) / 2);
  }
}
.header__desktopmenu > ul > li > .header__submenu li a {
  font-size: 18px;
}
.header__desktopmenu > ul > li > .header__submenu > ul > li {
  width: 210px;
}
.header__desktopmenu > ul > li > .header__submenu > ul > li ul {
  display: block;
  margin-top: 0.5rem;
}
.header__desktopmenu > ul > li > .header__submenu > ul > li ul a {
  text-transform: none;
}

.header__desktopmenu > ul > li.has-submenu:hover > .header__submenu {
  display: block;
}

.searchcontainer {
  width: 80vw;
  height: 100px;
  max-height: 50vh;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
@media (min-width: 768px) {
  .searchcontainer {
    height: 160px;
    background: #f0f0f0;
  }
}
.searchcontainer .searchform {
  width: 100%;
}
.searchcontainer .searchlabel {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  background: #fff;
  border-radius: 25px;
  padding: 20px 40px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  font-size: 29px;
}
@media (max-width: 768px) {
  .searchcontainer .searchlabel {
    font-size: 18px;
    padding: 10px 15px;
  }
}
.searchcontainer .searchlabel button {
  border: none;
  background: unset;
}
.searchcontainer .searchlabel #search {
  background: unset;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-family: "Futura Std";
  font-weight: 300;
  flex-grow: 1;
  width: 100%;
}
@media (min-width: 768px) {
  .searchcontainer .searchlabel {
    width: 796px;
    max-width: 80%;
  }
}

.mobilemenu {
  display: block;
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  background-color: #f0f0f0;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  text-align: right;
  z-index: 2000;
  transition: all 300ms;
  width: max-content;
  max-width: 90vw;
  min-width: 279px;
  font-family: "Helvetica Neue";
}
.mobilemenu.active {
  opacity: 1;
  pointer-events: auto;
}
.mobilemenu .ww-dropdown-icon-container {
  display: none;
}
.mobilemenu > .mobilemenu__items > li:nth-of-type(1n + 2) {
  margin-top: 0.5rem;
}
.mobilemenu > .mobilemenu__items > li > a .ww-dropdown-icon-container {
  display: inline-block;
}
.mobilemenu > .mobilemenu__items > li > .mobilemenu__submenu {
  height: 0;
  overflow: hidden;
}

.mobilemenu__itemlink--1 {
  margin-right: 16px;
}

.mobilemenu__itemlink--2 {
  margin-right: 32px;
}

.header__menu {
  display: none;
}

.header__contactlink {
  display: none;
}

@media (min-width: 900px) {
  .header__container {
    grid-template-areas: "logo menu side";
    grid-template-columns: auto 1fr auto;
  }
  .header__desktopmenu {
    display: flex;
    justify-content: center;
  }
  .header__menu {
    display: block;
  }
  .header__hamburger {
    display: none;
  }
  .header__contactlink {
    display: block;
  }
  .mobilemenu {
    display: none;
  }
}
@media (min-width: 1300px) {
  .header__desktopmenu .ww-menuitems {
    gap: 24px;
  }
  .ww-menuitems__menulink,
  a.header__contactlink {
    font-size: 20px;
  }
}
@media (min-width: 1450px) {
  .header__desktopmenu .ww-menuitems {
    gap: 20px;
  }
  .ww-menuitems__menulink,
  a.header__contactlink {
    font-size: 20px;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}