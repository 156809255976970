/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
/* Imports */
.wh-form__imgedit {
  width: 200px;
  height: 200px;
}
.wh-form__imgeditdelete {
  cursor: pointer;
}
.wh-form__imgeditholder::after {
  z-index: 1;
  position: absolute;
  display: none;
  width: 60px;
  top: 50%;
  left: 50%;
  height: 60px;
  font-size: 60px;
  line-height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  opacity: 0.8;
}
.wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form--uploading .wh-form__imgedit::after,
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}

.wh-form__textinput, .wh-form__textinput[type=password], .wh-form__uploadfieldfilename, .wh-form__textarea {
  width: 100%;
  font-size: var(--form-textinput-font-size);
  line-height: 100%;
  color: #000;
  font-weight: normal;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px;
  margin: 0;
  border: 1px solid #000;
  font-family: inherit;
}
.wh-form__textinput[disabled], .wh-form__textinput[type=password][disabled], .wh-form__uploadfieldfilename[disabled], .wh-form__textarea[disabled] {
  background: var(--ww-form-textinput-disabled-bg-color);
}
.wh-form__textarea {
  min-height: 120px;
}
.wh-form__dateinputgroup {
  max-width: 200px;
}
.wh-form__timeinputgroup {
  max-width: 140px;
}
.wh-form__textinput[type=number] {
  max-width: 200px;
}

.wh-form__uploadfield {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.wh-form__uploadfieldselect, .wh-form__uploadfieldfilename {
  cursor: pointer;
}
.wh-form__uploadfieldselect {
  white-space: nowrap;
  padding: 8px 24px;
  min-height: initial;
}
.wh-form__uploadfieldinputholder {
  flex-grow: 1;
  min-width: 300px;
  max-width: 100%;
}
.wh-form__uploadfieldfilename {
  width: 100%;
}
.wh-form__uploadfielddelete {
  top: 50%;
  transform: translate(-2px, -50%);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  width: 20px;
  line-height: 100%;
  position: absolute;
  right: 0;
}

/* Styling */
.wh-form__fieldgroup--required label::after {
  margin-left: 5px;
  content: "*";
  color: var(--form-color-error-required-indicator);
}
.wh-form__fields {
  width: 100%;
}
.wh-form__navbuttons {
  display: flex;
  justify-content: space-between;
}
.wh-form__button--next {
  margin-left: auto;
}
.wh-form__label {
  cursor: pointer;
}
.wh-form__fieldgroup {
  margin: 0 0 16px;
}
.wh-form__checkbox, .wh-form__radio {
  transform: scale(1.3);
  margin-right: 11px;
}
.wh-form__fieldgroup--checkbox.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkbox .wh-form__optionlabel::after, .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkboxgroup .wh-form__optionlabel::after, .wh-form__fieldgroup--radiogroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--radiogroup .wh-form__optionlabel::after {
  display: none;
}
.wh-form__fieldgroup--checkbox .wh-form__textinput, .wh-form__fieldgroup--checkboxgroup .wh-form__textinput, .wh-form__fieldgroup--radiogroup .wh-form__textinput {
  margin-left: 15px;
}
.wh-form__optionlabel {
  cursor: pointer;
}
.wh-form__fieldgroup--error .wh-form__textinput,
.wh-form__fieldgroup--error .wh-form__uploadfieldfilename,
.wh-form__fieldgroup--error .wh-form__textarea,
.wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: var(--ww-form-color-error-border);
}
.wh-form__error {
  color: var(--ww-form-color-error-message);
}

.wh-form__fieldgroup--required label::after {
  margin-left: 0;
}

.wh-anchor {
  scroll-margin-top: 90px;
}

.wh-form__fields .wh-anchor {
  scroll-margin-top: 155px;
}

/* H1 (header only): 84px
   H2: 50px
   H3/Intro: 20px
   Body: 19px
*/
:root {
  --bs-body-font-family: "Futura Std Condensed";
  --hk-font-h1-size: 84px;
  --hk-font-h2-size: 59px;
  --hk-font-h3-size: 20px;
  --hk-font-h4-size: 19px;
  --hk-font-body-size: 19px;
}
body,
ul,
ol,
p {
  font-size: var(--hk-font-body-size);
  line-height: 142%;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

h1,
.h1 {
  font-size: var(--hk-font-h1-size);
  font-weight: 300;
}

h2,
.h2 {
  font-size: var(--hk-font-h2-size);
  line-height: 100%;
  font-weight: 300;
}
h2::before,
.h2::before {
  content: "";
  display: block;
  margin: 0 auto 46px auto;
  height: 2px;
  width: 70px;
  background-color: var(--color-hk-gold);
}
h2.heading2gold,
.h2.heading2gold {
  color: var(--color-hk-gold) !important;
}
h2.noline::before,
.h2.noline::before {
  display: none;
}

h3,
h4,
.h3,
.h4 {
  font-size: var(--hk-font-h3-size);
  line-height: 125%;
  font-weight: 600;
}
@media (max-width: 768px) {
  h3,
  h4,
  .h3,
  .h4 {
    line-height: 25px;
  }
}

h4,
.h4 {
  font-size: var(--hk-font-h4-size);
  margin-bottom: 1em;
}

p.normal,
.pagecontent ol,
.pagecontent ul {
  font-family: "Futura Std";
  font-weight: 300;
}

a:not(.btn) {
  color: var(--color-hk-gold);
  text-decoration: none;
  transition: color 300ms;
}
a:not(.btn):hover {
  color: var(--color-hk-gold);
  text-decoration: underline;
}

@font-face {
  font-family: "Futura Std Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondensedlight.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: normal;
  font-weight: 300;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdlight.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Condensed";
  font-style: italic;
  font-weight: 300;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondensedlightobl.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: italic;
  font-weight: 300;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdlightoblique.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: normal;
  font-weight: 500;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdbook.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: italic;
  font-weight: 500;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdbookoblique.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Condensed";
  font-style: normal;
  font-weight: 600;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondensed.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: normal;
  font-weight: 600;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdmedium.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Condensed";
  font-style: italic;
  font-weight: 600;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondensedoblique.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: italic;
  font-weight: 600;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdmediumoblique.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: normal;
  font-weight: 700;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdheavy.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: italic;
  font-weight: 700;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdheavyoblique.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: normal;
  font-weight: 800;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdbold.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Condensed";
  font-style: normal;
  font-weight: 800;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondensedbold.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: italic;
  font-weight: 800;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdboldoblique.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Condensed";
  font-style: italic;
  font-weight: 800;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondensedboldobl.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Condensed";
  font-style: normal;
  font-weight: 900;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondensedextrabd.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Extra Bold";
  font-style: normal;
  font-weight: 900;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdextrabold.woff") format("woff");
}
@font-face {
  font-family: "Futura Std Condensed";
  font-style: italic;
  font-weight: 900;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdcondextraboldobl.woff") format("woff");
}
@font-face {
  font-family: "Futura Std";
  font-style: italic;
  font-weight: 900;
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/futura/futurastdextraboldoblique.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/helveticaneue/helveticaneuelight.woff2") format("woff2"), url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/helveticaneue/helveticaneuelight.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/helveticaneue/helveticaneuemedium.woff2") format("woff2"), url("~@mod-holtkamp/webdesigns/holtkamp/web/fonts/helveticaneue/helveticaneuemedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
h2 a,
h3 a,
h4 a {
  transition: color 0.3s;
  text-decoration: none !important;
  display: inline-block;
  position: relative;
  z-index: 1;
}
h2 a:after,
h3 a:after,
h4 a:after {
  content: "";
  position: absolute;
  top: -6px;
  right: -5px;
  bottom: 0px;
  left: -5px;
  background: var(--color-hk-gold);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}
@media (hover: hover) {
  h2 a:hover,
  h3 a:hover,
  h4 a:hover {
    color: #fff !important;
  }
  h2 a:hover:after,
  h3 a:hover:after,
  h4 a:hover:after {
    opacity: 1;
  }
}

:root {
  --color-hk-red: #d52329;
  --color-hk-gold: #bc9e56;
  --color-hk-black: #000000;
  --color-hk-gold-hover-dark: #9d813e;
  --color-hk-gray-darkish: #e6e6e6;
  --hk-body-bg-color: rgba(0 0 0 / 3%);
  --hk-widget-vertical-spacing: 2rem;
  --hk-widget-padding-x: 32px;
  --hk-content-width-desktop: calc(100vw - 420px - 60px);
  --hk-page-side-padding: 1rem;
}
@media (min-width: 968px) {
  :root {
    --hk-widget-padding-x: 48px;
  }
}

fieldset,
legend {
  all: revert;
}

legend {
  background-color: var(--color-hk-gold);
  color: #fff;
  padding: 6px 10px;
  font-size: var(--hk-font-h4-size);
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  background-color: var(--hk-body-bg-color);
}
body > main {
  flex: 1;
  position: relative;
}

.grid, .w-slideshow__text,
.header__submenu.header__submenu--0 > .ww-menuitems {
  width: 100%;
  padding-left: var(--hk-page-side-padding);
  padding-right: var(--hk-page-side-padding);
  max-width: calc(1200px + 2 * var(--hk-page-side-padding)) !important;
  margin-left: auto;
  margin-right: auto;
}

wh-authorbar {
  display: none !important;
}

.crumbpath {
  padding: 0 0 2rem;
  display: none;
  justify-content: center;
}

.crumbpath__item,
.crumbpath__separator {
  margin-right: 8px;
}

.crumbpath__separator {
  font-size: 80%;
}

.crumbpath__outergrid {
  background: #f1f1f1;
}

@media (min-width: 768px) {
  .crumbpath {
    display: flex;
  }
}
.pagecontent {
  margin-top: 0 !important;
  max-width: 100vw;
  background: #fff;
  overflow: hidden;
}

ul.reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wh-rtd__tablewrap.table {
  overflow-x: auto;
}

.offcanvas {
  z-index: 600;
}

.offcanvas-backdrop {
  z-index: 500;
}

.offcanvas .navbar-nav {
  gap: 1rem;
}

.offcanvas .nav-item .dropdown-toggle::after {
  transform: translateY(3px);
}

.offcanvas .nav-item .dropdown-toggle.show::after {
  transform: translateY(3px) scaleY(-1);
}

.contentblocker {
  opacity: 0;
  pointer-events: none;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 400;
  transition: opacity 0.3s;
  align-items: flex-start;
  justify-content: center;
}
.contentblocker.active {
  opacity: 1;
  pointer-events: auto;
  position: fixed;
}
@media (min-width: 768px) {
  .contentblocker {
    align-items: center;
  }
}

a.btn,
a.btn-primary,
button.btn,
button.btn-primary,
.wh-form__button {
  --bs-btn-border-width: 1px;
  --bs-btn-hover-border-color: #000;
  --bs-btn-border-color: var(--color-hk-gold);
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-color: #000;
  padding: 10px 12px;
  font-weight: 600;
  font-size: 18px;
  background-color: var(--color-hk-gold);
  color: #fff;
  border-radius: 0;
  line-height: 1;
  min-width: 215px;
  max-width: max-content;
  text-transform: uppercase;
  outline: 0;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  transition: all 300ms;
}
a.btn:hover,
a.btn-primary:hover,
button.btn:hover,
button.btn-primary:hover,
.wh-form__button:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.w-text[data-bigbuttons=true] a.btn,
.w-text[data-bigbuttons=true] a.btn-primary,
.w-text[data-bigbuttons=true] button.btn,
.w-text[data-bigbuttons=true] button.btn-primary,
.w-text[data-bigbuttons=true] .wh-form__button {
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 0.5em;
  padding: 25px 20px 20px 40px;
}

button.btn,
button.btn-primary {
  min-width: initial;
  padding-left: 1rem;
  padding-right: 1rem;
}

.background-pattern {
  position: absolute;
  inset: 0;
  background-color: #fff;
}

.background-pattern__pattern {
  position: absolute;
  inset: 0;
  background-size: 80%;
  z-index: 1;
  opacity: 0.5;
  background-size: 50%;
}

.socials {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  align-items: center;
  margin-top: 32px;
}

.sidebar {
  top: 0;
  padding-top: var(--hk-widget-vertical-spacing);
  padding-bottom: var(--hk-widget-vertical-spacing);
  z-index: 200;
  width: 100%;
  position: sticky;
  background-color: rgb(241, 241, 241);
}
.sidebar__nav {
  max-width: 210px;
  margin: 0 auto;
  display: none;
}
.sidebar__title {
  text-transform: uppercase;
  font-size: 21px;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 22px;
}
.sidebar__title:not(.first) {
  margin-top: 54px;
  margin-left: 16px !important;
}
.sidebar__links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sidebar__link {
  font-size: 22px;
  line-height: 1;
  max-width: max-content;
}
.sidebar__link.active {
  padding: 3px 5px;
  background-color: var(--color-hk-gold);
  color: #fff;
}
.sidebar__link a {
  color: inherit;
  text-decoration: none;
}
.sidebar__nav--mobile {
  display: block;
  max-width: 85%;
  margin: 0 auto;
  text-align: center;
}
.sidebar__nav--mobile .sidebar__links {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.sidebar__nav--mobile .sidebar__title {
  color: var(--color-hk-gold);
  margin: 0;
}

ul,
ol {
  padding-left: 1em;
}

.tastebox {
  position: relative;
  padding: 35px 30px;
  width: 100%;
}
@media (max-width: 768px) {
  .tastebox {
    padding: var(--hk-page-side-padding);
  }
}
.tastebox__textcontainer {
  position: relative;
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  padding: 65px 25px;
  background-color: #fff;
  border: 1px solid var(--color-hk-gold);
}
@media (min-width: 768px) {
  .tastebox__textcontainer {
    padding: 65px 104px;
  }
}
.tastebox__text {
  text-align: center;
  text-transform: uppercase;
}
.tastebox__text h2 {
  margin-bottom: 30px;
  font-weight: 300;
}
.tastebox__text h2::before {
  content: "";
  display: block;
  margin: 0 auto 46px auto;
  height: 2px;
  width: 70px;
  background-color: var(--color-hk-gold);
}
.tastebox__text p {
  color: var(--color-hk-gold);
  font-size: 22px;
  line-height: 29px;
  margin-bottom: 46px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .tastebox__text p {
    font-size: 19px;
    line-height: 25px;
  }
}
.tastebox__text a.btn {
  padding: 12px 44px;
  font-size: 22px;
}
@media (min-width: 768px) {
  .tastebox {
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.navbar {
  padding: 0;
}

.formpage {
  position: relative;
  padding-bottom: 5rem;
}
.formpage .wh-form {
  position: relative;
  z-index: 10;
}
.formpage .wh-form__fieldgroup {
  margin-bottom: 0;
}
.formpage .wh-form__fieldgroup:not(.wh-form__fieldgroup--richtext),
.formpage .wh-form__navbuttons {
  background: #f0f0f0;
  padding: var(--hk-widget-vertical-spacing) 78px;
}
.formpage .wh-form__fieldgroup:not(.wh-form__fieldgroup--richtext):first-of-type,
.formpage .wh-form__navbuttons:first-of-type {
  padding: 70px 78px;
}
.formpage .wh-form__fieldgroup {
  font-family: "Futura Std";
  font-weight: 300;
}
.formpage .wh-form__fieldgroup--radiogroup .wh-form__fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  padding: 20px 45px;
}
@media (min-width: 768px) {
  .formpage .wh-form__fieldgroup--radiogroup .wh-form__fields {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 45px;
  }
}
.formpage .wh-form__fieldgroup--radiogroup .wh-form__fieldline {
  display: flex;
  align-items: center;
  gap: 8px;
}
.formpage .wh-form__fieldgroup--radiogroup .wh-form__radio {
  width: 42px;
  height: 42px;
  transform: scale(0.9);
}
.formpage .wh-form__fieldgroup--radiogroup a.wh-anchor {
  display: none;
}
.formpage .wh-form__fieldgroup--richtext {
  background: #fff;
}
.formpage .wh-form__fieldgroup:nth-of-type(n + 2) .wh-form__fields {
  margin-top: 14px;
}
.formpage .wh-form__fieldgroup--radiogroup .wh-form__fields {
  padding-left: 20px;
}
.formpage .wh-form__textinput {
  width: 100%;
  border: none;
  height: 80px;
  padding: 0 16px;
}
.formpage .wh-form__label {
  padding-left: 16px;
}
.formpage .wh-form__error {
  margin-top: 7px;
  padding-left: 16px;
}
.formpage .wh-form__button--submit {
  font-size: 37px;
}

.w-textimage {
  --ww-widget-textimage-border-radius: 0;
  --ww-widget-textimage-column-text-width: 48.5%;
  --ww-widget-textimage-column-image-width: 44.5%;
}
.w-textimage__text {
  max-width: 470px;
  margin-left: auto;
}

@media (min-width: 11140px) {
  .sidebar {
    position: sticky;
    max-width: calc((100vw - min(1200px, var(--hk-content-width-desktop))) / 2 - 7px);
  }
  .sidebar__nav {
    display: block;
  }
  .sidebar__nav--mobile {
    display: none;
  }
}
.wh-form__richtext {
  padding-bottom: 2rem;
}

.btn-check:focus + .btn,
.btn:focus {
  color: inherit;
  box-shadow: none;
}