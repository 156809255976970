.w-text {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  max-width: 650px;
}
.w-text--text-left {
  text-align: left;
}
.w-text > *:last-child {
  margin-bottom: 0;
}

.widget--text[data-fullwidth=true] {
  background-color: #fff !important;
  max-width: 100%;
}

.widget--text[data-backgroundred=true] {
  background-color: #af1d33 !important;
}
.widget--text[data-backgroundred=true] {
  color: #fff;
}

html.schatje .widget--text {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: unset;
}