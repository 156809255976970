/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-dish_tip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 20px;
}

.w-dish_tip__tipcontainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.w-dish_tip__tip {
  background-color: var(--color-hk-gold);
  color: #fff !important;
  padding: 25px 12px 4px 12px;
  max-width: 528px;
  width: 100%;
  margin: 0 auto;
  display: block;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s !important;
}

a.w-dish_tip__tip {
  cursor: pointer;
}
@media (hover: hover) {
  a.w-dish_tip__tip:hover {
    text-decoration: none !important;
    background-color: var(--color-hk-gold-hover-dark);
  }
}

.w-dish_tip__tiptitle {
  text-transform: uppercase;
  letter-spacing: 15px;
  font-weight: 700;
  font-size: var(--hk-font-h2-size);
  margin-bottom: 52px;
  line-height: 1;
}
.w-dish_tip__tiptitle::after {
  content: "";
  display: block;
  margin: 7px auto 0 auto;
  height: 2px;
  width: 70px;
  background-color: #fff;
}
.w-dish_tip__tiptitle::after {
  transform: translateX(-10px);
}

.w-dish_tip__extratext {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .w-dish_tip {
    flex-flow: row wrap;
    align-items: flex-start;
  }
  .w-dish_tip__tip {
    min-height: 318px;
    padding: 25px 36px 4px 36px;
  }
  .w-dish_tip__extratext {
    margin-top: 65px;
  }
}