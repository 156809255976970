.w-fourimages__imagescontainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;
}
@media (min-width: 768px) {
  .w-fourimages__imagescontainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .w-fourimages__imagescontainer {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.w-fourimages__imagecontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e2e3;
  background: #fff;
}
.w-fourimages__image {
  max-width: 100%;
  height: 100%;
}
.w-fourimages__textcontainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
}
@media (min-width: 1140px) {
  .w-fourimages__textcontainer {
    grid-template-columns: minmax(300px, 1fr) auto minmax(300px, 1fr);
    gap: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.w-fourimages__filler, .w-fourimages__textside {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (min-width: 1140px) {
  .w-fourimages__filler, .w-fourimages__textside {
    padding-top: 74px;
    padding-bottom: 74px;
  }
}
.w-fourimages__textbelow {
  text-align: center;
  font-family: "Futura Std";
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  font-size: 42px;
  line-height: 51px;
  padding-top: 62px;
}
@media (min-width: 1140px) {
  .w-fourimages__textbelow {
    padding-top: 104px;
    padding-bottom: 104px;
  }
}
@media (min-width: 1800px) {
  .w-fourimages__textbelow {
    width: 100vw;
  }
}
.w-fourimages__textside {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: center;
}
@media (min-width: 1140px) {
  .w-fourimages__textside {
    text-align: left;
  }
}
.w-fourimages__textside span {
  display: block;
  max-width: 300px;
  margin: 0 auto;
  padding: 0 10px;
}
.w-fourimages__filler, .w-fourimages__textside {
  background: var(--hk-body-bg-color);
}
@media (max-width: 1140px) {
  .w-fourimages__filler {
    display: none;
  }
}

.widget--fourimages + .widget--fourimages {
  position: relative;
  top: -1px;
}
.widget--fourimages + .widget--fourimages .w-fourimages__imagecontainer {
  border: 0;
}