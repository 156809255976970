.widget.widget--wideimage,
.w-wideimage .widget--slideshow {
  padding-top: 0;
  padding-bottom: 0;
}

.w-wideimage .w-slideshow {
  height: auto;
  max-height: 75vh;
  min-height: 300px;
}
.w-wideimage .w-slideshow {
  padding: 7rem 0;
}

.w-slideshow__text > *:last-child {
  margin-bottom: 0 !important;
}