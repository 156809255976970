/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.widget.widget--contactblocks {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .w-contactblocks {
    padding-top: 2rem;
  }
}
.w-contactblocks__blockscontainer {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .w-contactblocks__blockscontainer {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.w-contactblocks__textcontainer {
  background: #e2e2e2;
  text-align: center;
  padding: 3rem 1.5rem;
}
@media (min-width: 992px) {
  .w-contactblocks__textcontainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.w-contactblocks__textcontainer--0, .w-contactblocks__textcontainer--2 {
  background: #ebebeb;
}
.w-contactblocks__textcontainer .widget--button {
  margin-top: 27px;
}
.w-contactblocks__textcontainer .btn {
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .w-contactblocks__textcontainer .btn {
    font-size: 1.5rem;
    letter-spacing: 1.5px;
    padding-top: 10px;
  }
}

.w-contactblocks__text {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.w-contactblocks__text .widget--button {
  margin-top: auto;
  padding-top: 26px;
}