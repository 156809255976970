/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.footer {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 35px 62px;
  text-transform: uppercase;
  font-size: 18px;
}
@media (max-width: 768px) {
  .footer {
    background-color: #fff;
    padding: 35px var(--hk-page-side-padding);
  }
}
.footer__cols {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media (min-width: 768px) {
  .footer__cols {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 35px 0;
    gap: 3rem;
  }
}
@media (min-width: 1400px) {
  .footer__cols {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
.footer__cols, .footer__links {
  list-style-type: none;
}
.footer__col {
  display: flex;
  flex-direction: column;
}
.footer__title, .footer__link,
.footer p.normal {
  color: rgba(0, 0, 0, 0.85);
  font-family: "Futura Std Condensed";
  font-size: 18px;
}
.footer__title {
  font-weight: 600;
  color: inherit !important;
  text-decoration: none !important;
}
@media (hover: hover) {
  .footer__title:hover {
    color: var(--color-hk-gold) !important;
  }
}
.footer__links {
  display: flex;
  flex-direction: column;
}
.footer__link {
  font-weight: 300;
}
.footer__link a {
  color: inherit;
  text-decoration: none;
}
.footer .subscribe-newsletter {
  margin-bottom: 32px;
}
.footer .footer__expand {
  color: var(--color-hk-gold);
}
@media (min-width: 768px) {
  .footer .footer__expand {
    display: none;
  }
}
@media (min-width: 768px) {
  .footer__links, .footer__content {
    height: auto !important;
  }
}
@media (max-width: 767.98px) {
  .footer .footer__content,
  .footer .footer__links {
    height: 0;
    overflow: hidden;
  }
  .footer .footer__title {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer .footer__col {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-hk-gold);
  }
  .footer .footer__col--subscribe {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .footer .subscribe-newsletter {
    font-size: 16px;
    padding: 7px;
    margin: 0;
    min-width: 186px;
  }
  .footer .socials {
    gap: 10px;
  }
}