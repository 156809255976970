/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-text_textblock {
  background-color: #fff;
  display: grid;
  flex-direction: column;
  gap: 32px;
  padding: 48px var(--hk-page-side-padding);
  grid-template-columns: 1fr;
}
.w-text_textblock__textcontainerleft h2 {
  text-align: center;
}
.w-text_textblock__textcontainerright {
  position: relative;
  padding: 20px var(--hk-page-side-padding) 40px;
  background-color: var(--color-hk-gray-darkish);
  background-color: #fff;
  border: 1px solid #000;
}
.w-text_textblock__backgroundright {
  position: relative;
  background-color: var(--color-hk-gray-darkish);
  background-color: transparent;
  z-index: 9;
}
.w-text_textblock__textright {
  position: relative;
  z-index: 10;
}
@media (min-width: 768px) {
  .w-text_textblock {
    grid-template-columns: 1fr 400px;
  }
  .w-text_textblock__backgroundright {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: -100vw;
    z-index: 9;
  }
}
@media (min-width: 992px) {
  .w-text_textblock {
    padding-right: 32px;
  }
  .w-text_textblock .w-text_textblock__textcontainerleft {
    padding-left: 32px;
    padding-right: 32px;
  }
}