/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-team__members {
  display: grid;
  gap: 84px;
  grid-template-columns: 1fr;
}
@media (min-width: 576px) {
  .w-team__members {
    grid-template-columns: repeat(2, 1fr);
  }
}
.w-team__member {
  max-width: 472px;
  width: 100%;
}
.w-team__image {
  transition: transform 0.3s;
  width: 100%;
  aspect-ratio: 472/328;
  object-fit: cover;
}
.w-team__imagecontainer {
  position: relative;
  overflow: hidden;
  margin-bottom: 32px;
}
@media (hover: hover) {
  .w-team__imagecontainer:hover .w-team__image {
    transform: scale(1.03);
  }
}
.w-team__textcontainer {
  text-align: center;
}
.w-team__name {
  color: var(--color-hk-gold);
  font-size: var(--hk-font-h2-size);
  line-height: 42px;
  text-transform: uppercase;
  margin-bottom: 9px;
}
.w-team__position {
  font-size: 22px;
  line-height: 29px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .w-team__position {
    margin-bottom: 24px;
  }
}
.w-team__description {
  font-family: "Futura Std";
  font-weight: 300;
  line-height: 27px;
  color: #707070;
}
.w-team__description::after {
  content: "";
  display: block;
  margin: 32px auto 0 auto;
  height: 2px;
  width: 70px;
  background-color: var(--color-hk-gold);
}