section.widget {
  padding-top: var(--hk-widget-vertical-spacing);
  padding-bottom: var(--hk-widget-vertical-spacing);
}

main > :first-child.widget--slideshow,
section.widget--fourimages,
section.widget--text_textblock {
  padding-top: 0;
  padding-bottom: 0;
}

.widget-container,
.widget--textimage,
.widget-container .w-text[data-fullwidth=false],
.crumbpath,
.formpage .wh-form__fieldgroup:not(.wh-form__fieldgroup--richtext),
.formpage .wh-form__navbuttons {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.wh-rtd > h1,
.wh-rtd > h2,
.wh-rtd > h3,
.wh-rtd > h4,
.wh-rtd > .wh-video,
.wh-rtd > p.normal,
.wh-form__richtext > h1,
.wh-form__richtext > h2,
.wh-form__richtext > h3,
.wh-form__richtext > h4,
.wh-form__richtext > .wh-video,
.wh-form__richtext > p.normal {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--hk-page-side-padding);
  padding-right: var(--hk-page-side-padding);
  background-color: #fff;
}
.wh-rtd > h1:first-child,
.wh-rtd > h2:first-child,
.wh-rtd > h3:first-child,
.wh-rtd > h4:first-child,
.wh-rtd > .wh-video:first-child,
.wh-rtd > p.normal:first-child,
.wh-form__richtext > h1:first-child,
.wh-form__richtext > h2:first-child,
.wh-form__richtext > h3:first-child,
.wh-form__richtext > h4:first-child,
.wh-form__richtext > .wh-video:first-child,
.wh-form__richtext > p.normal:first-child {
  padding-top: 2rem;
}
.wh-rtd > h1:last-child,
.wh-rtd > h2:last-child,
.wh-rtd > h3:last-child,
.wh-rtd > h4:last-child,
.wh-rtd > .wh-video:last-child,
.wh-rtd > p.normal:last-child,
.wh-form__richtext > h1:last-child,
.wh-form__richtext > h2:last-child,
.wh-form__richtext > h3:last-child,
.wh-form__richtext > h4:last-child,
.wh-form__richtext > .wh-video:last-child,
.wh-form__richtext > p.normal:last-child {
  padding-bottom: 2rem;
}
.wh-rtd > h1:not(:last-child),
.wh-rtd > h2:not(:last-child),
.wh-rtd > h3:not(:last-child),
.wh-rtd > h4:not(:last-child),
.wh-rtd > .wh-video:not(:last-child),
.wh-rtd > p.normal:not(:last-child),
.wh-form__richtext > h1:not(:last-child),
.wh-form__richtext > h2:not(:last-child),
.wh-form__richtext > h3:not(:last-child),
.wh-form__richtext > h4:not(:last-child),
.wh-form__richtext > .wh-video:not(:last-child),
.wh-form__richtext > p.normal:not(:last-child) {
  margin-bottom: 0;
  padding-bottom: 2rem;
}
.wh-rtd .wh-video > iframe,
.wh-form__richtext .wh-video > iframe {
  width: calc(100% - 64px) !important;
}
.wh-rtd > h2,
.wh-rtd > h3,
.wh-form__richtext > h2,
.wh-form__richtext > h3 {
  text-align: center;
}

.widget-padding,
.widget--textimage {
  padding-left: var(--hk-widget-padding-x);
  padding-right: var(--hk-widget-padding-x);
}

.widget-container.full-width {
  max-width: 100%;
  width: 100%;
}

section.widget--dishes,
section.widget--contactblocks .w-contactblocks,
section.widget.widget-container,
section.widget--textimage {
  background-color: #fff;
}

section.widget--ambassadors {
  background-color: transparent;
}

section.widget--productsblock {
  background-color: #f0f0f0;
}