/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-productsblock__title {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  margin-bottom: 16px;
}
.w-productsblock__products {
  display: grid;
  gap: 32px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
@media (min-width: 768px) {
  .w-productsblock__products {
    grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  }
}
.w-productsblock__product {
  text-decoration: none !important;
}
.w-productsblock__imagecontainer {
  width: 100%;
  margin-bottom: 40px;
  display: block;
  aspect-ratio: 1;
}
@media (max-width: 768px) {
  .w-productsblock__imagecontainer {
    margin-bottom: 20px;
  }
}
.w-productsblock__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.w-productsblock__producttitle {
  font-size: 26px;
  line-height: 100%;
  color: #000;
  text-align: center;
  font-weight: 300;
}
@media (min-width: 768px) {
  .w-productsblock__producttitle {
    font-size: 35px;
    line-height: 100%;
  }
}
.w-productsblock--fontgoldinside .w-productsblock__product {
  border: 1px solid #fff;
  background-color: #fff;
  width: 100%;
}
@media (hover: hover) {
  .w-productsblock--fontgoldinside .w-productsblock__product[href]:hover {
    border-color: var(--color-hk-gold);
  }
  .w-productsblock--fontgoldinside .w-productsblock__product[href]:hover .w-productsblock__producttitle {
    background-color: var(--color-hk-gold);
    color: #fff;
  }
}
.w-productsblock--fontgoldinside .w-productsblock__producttitle {
  color: var(--color-hk-gold);
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 7px;
  width: max-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: all 300ms;
  padding: 5px 5px 2px;
  pointer-events: none;
}

.w-productsblock__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 1.5rem;
}
.w-productsblock__filters,
.w-productsblock__filters a {
  color: #000;
  font-size: 24px;
}
.w-productsblock__filters a {
  cursor: pointer;
}
.w-productsblock__filters a:not(:last-child)::after {
  content: "|";
  margin-left: 8px;
}
.w-productsblock__filters a.selected {
  color: var(--color-hk-gold);
}