/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.widget.widget--contactmap {
  padding-top: 0;
  padding-bottom: 0;
}

.w-contactmap {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}
.w-contactmap__imagecontainer {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.w-contactmap__image {
  height: 50vh;
  width: 100vw;
  object-fit: cover;
}
.w-contactmap__textcontainer {
  padding: 48px 32px;
  margin: 0 auto;
}
.w-contactmap__socials {
  margin-top: 2rem;
}
.w-contactmap__socials .socials {
  justify-content: flex-start;
}

.w-contactmap__title {
  text-align: center;
}

@media (min-width: 576px) {
  .w-contactmap {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .w-contactmap__imagecontainer {
    position: relative;
  }
  .w-contactmap__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .w-contactmap__textcontainer {
    order: 2;
    margin: 0;
    padding: 4rem;
  }
}