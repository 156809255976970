.p-search {
  margin: 0;
  padding: 0 0 3rem;
}
.p-search .widget-container {
  background: #fff;
  padding: 2rem;
}
.p-search__resulttitle {
  margin: 0 0 20px;
}
.p-search .p-form {
  margin: 0 0 20px;
  display: flex;
  flex-flow: wrap;
}
.p-search .p-form .wh-form__textinput {
  width: 250px;
  max-width: 100%;
  height: 40px;
  margin: 0 10px 0 0;
  padding-left: 0.5rem;
}
.p-search .p-form__formsearchbutton {
  padding-left: 30px;
  padding-right: 30px;
  min-width: initial;
}
.p-search .p-form__formsearchbutton::after {
  display: none;
}
.p-search .p-results__titlecontainer {
  margin: 0 0 5px;
}
.p-search .p-results__title {
  font-size: 160%;
}
.p-search .p-results__row {
  margin: 0 0 35px;
}
.p-search .p-results__linkcontainer {
  margin: 0 0 6px;
}
.p-search .p-results .match {
  font-weight: 700;
}