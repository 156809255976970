.w-ctas__ctascontainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
}
@media (min-width: 768px) {
  .w-ctas__ctascontainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 95px;
  }
}
.w-ctas__cta {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
}
.w-ctas__imagecontainer {
  position: relative;
  aspect-ratio: 1;
}
.w-ctas__image {
  z-index: 3;
}
.w-ctas__textcontainer {
  padding: 30px 70px 54px 70px;
  text-align: center;
  flex-grow: 1;
}
@media (max-width: 768px) {
  .w-ctas__textcontainer {
    padding: 20px 39px;
  }
}
.w-ctas__border {
  position: absolute;
  top: 50px;
  left: 33px;
  right: 33px;
  bottom: 0;
  z-index: 1;
  border: 1px solid var(--color-hk-gold);
}
@media (max-width: 768px) {
  .w-ctas__border {
    top: 30px;
    left: 20px;
    right: 20px;
    bottom: 0;
  }
}
.w-ctas__title {
  color: #000;
}
.w-ctas__description {
  color: var(--color-hk-gold);
  font-size: 20px;
  font-weight: 500;
  max-width: 330px;
  margin: 0 auto;
}
.w-ctas__cta:nth-of-type(even) .w-ctas__textcontainer {
  background-color: var(--color-hk-gold);
}
.w-ctas__cta:nth-of-type(even) .w-ctas__title,
.w-ctas__cta:nth-of-type(even) .w-ctas__description {
  color: #fff;
}
.w-ctas__cta:nth-of-type(even) .w-ctas__border {
  z-index: 3;
}

.w-ctas__description {
  font-size: 22px;
  line-height: 29px;
  font-weight: 500;
  text-transform: uppercase;
}