/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-dishes {
  display: flex;
  justify-content: center;
}
.w-dishes__dishescontainer {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 28px;
  padding: 20px;
}
@media (min-width: 1200px) {
  .w-dishes__dishescontainer {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 57px;
  }
}
.w-dishes__dishcontainer {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid var(--color-hk-gold);
}
.w-dishes__imagecontainer {
  border: 1px solid var(--color-hk-gold);
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 46px;
  height: 298px;
}
.w-dishes__image {
  max-width: 100%;
  height: 100%;
}
.w-dishes__textcontainer {
  max-width: 306px;
  text-align: center;
  margin: 0 auto;
}
.w-dishes__title {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.w-dishes__title::after {
  content: "";
  display: block;
  margin: 16px auto 0 auto;
  height: 2px;
  width: 70px;
  background-color: var(--color-hk-gold);
}
.w-dishes__text p.normal {
  margin: 0 0 1rem;
}
.w-dishes__text p.normal b {
  font-weight: 600;
  letter-spacing: 0.5px;
}
.w-dishes--widget-dishes {
  width: 100%;
}
.w-dishes--widget-dishes .w-dishes__imagecontainer {
  height: 300px;
}
.w-dishes--widget-dishes .w-dishes__image {
  height: 100%;
  object-fit: cover;
}
.w-dishes--widget-dishes .w-dishes__dishescontainer {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  gap: 3rem !important;
}
.w-dishes--widget-dishes .w-dishes__dishcontainer {
  width: 300px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.w-dishes--widget-dishes .w-dishes__dishcontainer,
.w-dishes--widget-dishes .w-dishes__textcontainer {
  max-width: initial;
}
.w-dishes--widget-dishes .w-dishes__textcontainer {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 1200px) {
  .w-dishes--widget-dishes .w-dishes__dishescontainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .w-dishes--widget-dishes .w-dishes__dishcontainer {
    width: 100%;
  }
}